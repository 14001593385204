.huddle {
  &-volume-slider {
    writing-mode: bt-lr;
    -webkit-appearance: slider-vertical;
    appearance: slider-vertical;
    height: 100px;
    width: 8px;
    background: linear-gradient(to bottom, #ddd, #bbb);
    border-radius: 5px;
    outline: none;
    cursor: pointer;
  }

  &-audio-controls {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &-speaker-button {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
  }
}
