.invalid-feedback {
  display: block;
}

.nav-link.disabled,
.nav-link:disabled {
  color: #cccccc !important;
}

.team-list {
  justify-content: center;
}

.find-school-wrapper {
  .find-school-btn {
    cursor: pointer;
  }

  .find-school-btn:disabled {
    cursor: none;
  }
}

.current-profile-wrapper {
  position: relative;

  .profile-box {
    font-size: 13px;
  }

  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.clases-wrapper {
  h5 {
    min-height: 40px;
  }
}

.analyticsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .barGraph {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 60px;
  }

  .lineChart {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 90px;
  }
}

.no-rows {
  td {
    text-align: center;
  }
}

.custom-otp-container {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.custom-otp-input {
  width: 60% !important;
  height: 40px;
  text-align: center !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  // background-color: rgb(133, 174, 214);
}

.login-button {
  margin-top: 70px;
}

.analytics-container {
  .ico-lg {
    font-size: 20px;
  }

  .color-grey {
    color: grey;
  }

  .dyte-wrapper {
    .avatar-xl {
      width: 100%;
      height: 175px !important;
    }

    .rounded-circle {
      border-radius: 10px !important;
    }
  }

  .tbl-class-summary {
    table {
      th {
        color: #212529;
      }
    }
  }

  .gauge-container {
    position: relative;

    .lbl-poor {
      font-size: 10px;
      position: absolute;
      bottom: 10px;
      left: -5px;
    }

    .lbl-good {
      font-size: 10px;
      top: -20px;
      left: 45%;
      position: absolute;
      margin-top: 4px;
    }

    .lbl-excellent {
      font-size: 10px;
      position: absolute;
      bottom: 10px;
      right: -18px;
    }

    .gauge-chart {
      width: 200px !important;
      height: 70px !important;
    }
  }

  .progress-text {
    color: black;
    font-size: small;
    padding: 4px;
  }

  .star-icon {
    font-size: 20px;
  }

  .timer-text {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: inline-block;
    margin-left: 10px;
    bottom: 30px;
    right: 10px;
  }

  .timer {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: inline-block;
    margin-left: 10px;
    bottom: 10px;
    right: 10px;
  }

  .class-heading {
    display: inline-block;
  }

  .live-view-heading {
    position: absolute;
    z-index: 1;
  }

  .live-view {
    position: relative;
    z-index: 0;
  }

  .gauge-chart.disabled {
    opacity: 0.1;
    pointer-events: none;
  }

  .book-img {
    width: 150px;
    height: 140px;
    border: 1px solid #cbcbcb;
    margin-right: 10px;
  }

  .centered-icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 2rem;
    background: #ffffff;
    border-radius: 50%;
    width: 33px;
    height: 34px;
  }
}

.guardian-student-dd {
  .btn-label {
    width: 300px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dropdown-menu {
    width: 100%;
  }
}

.overlayStyle {
  background-color: rbg(0, 0, 0);
  position: 'absolute';
  filter: blur(10px);
}

// .accordion-button::after {
//   display: none !important;
// }

/* Styles for Accordion Container */
// .accordion-container {
//   display: flex;
//   justify-content: space-between;
// }

// /* Shared Styles for Accordion Item */
// .accordion-item {
//   flex: 1;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column;
//   border-radius: 8px;
//   border: 1px solid #e9ebec;
//   background: #fff;
//   margin-bottom: 20px;
// }

// /* Styles for Accordion Header */
// .accordion-header {
//   display: flex;
//   // padding: 18px 16px;
//   justify-content: center;
//   align-items: center;
//   gap: 8px;
//   color: #495057;
//   font-family: 'Poppins', sans-serif;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: normal;
//   letter-spacing: 0.1px;
//   outline: none; /* Remove outline on focus */
// }

// /* Styles for Accordion Collapse */
// .accordion-collapse {
//   flex: 1;
//   overflow: hidden;
// }

.custom-btn-color {
  background-color: #6c80fe !important;
  border-color: #6c80fe !important;
}

.custom-btn-txt-color {
  color: #6c80fe;
}

.custom-right-modal {
  max-width: 25%; // Set the maximum width to 25% of the viewport width
  margin-right: true; // Move the modal to the right
}

.modal-header-custom {
  background-color: #f1f3ff;

  p {
    margin-bottom: 5px; /* Adjust the value based on your preference */
  }
}

.booked-button {
  background-color: #6c80fe;
}

.w-80 {
  width: 80px;
}

.load-more-btn-wrapper {
  align-items: center;
  text-align: center;
  justify-content: center;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .w-80 {
    width: 0;
  }
}

.dropzone {
  border: 1px dashed #ccc;
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .dz-default {
    text-align: center;
    font-size: 16px;
    color: #666;
  }

  &:hover {
    border-color: #007bff; // Change the border color on hover if desired
  }
}

.notebook-stuVideo-tile {
  .video-tile {
    width: auto;
    height: auto;
  }

  .student-notes-ctx {
    overflow: auto;
    width: 100%;
  }

  .notebook-stuVideo-wrapper {
    .overlay {
      --border-style: 3px solid red;
      --border-space: 10px;

      position: absolute;
      width: 100%;
      height: auto;
    }

    .overlay-helper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .overlay-element {
      padding: 20px;
      width: 80px;
      height: 50px;
      position: absolute;
    }

    .overlay-text {
      font-size: 1.75em;
      color: white;
      font-family: monospace;
    }

    .overlay .top-left {
      border-left: var(--border-style);
      border-top: var(--border-style);
      top: var(--border-space);
      left: var(--border-space);
      text-align: left;
      z-index: 1;
    }

    .overlay .top-right {
      border-right: var(--border-style);
      border-top: var(--border-style);
      top: var(--border-space);
      right: 50px;
      text-align: right;
      z-index: 1;
    }

    .overlay .bottom-left {
      border-left: var(--border-style);
      border-bottom: var(--border-style);
      bottom: var(--border-space);
      left: var(--border-space);
      text-align: left;
      z-index: 1;
    }

    .overlay .bottom-right {
      border-right: var(--border-style);
      border-bottom: var(--border-style);
      bottom: var(--border-space);
      right: var(--border-space);
      text-align: right;
      z-index: 1;
    }

    #overlay-bottom-left-text {
      position: absolute;
      bottom: var(--border-space);
      left: var(--border-space);
    }

    #overlay-bottom-right-text {
      position: absolute;
      bottom: var(--border-space);
      right: var(--border-space);
    }
  }
}

.payment-card {
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

// Class Notes CSS
.notes-date {
  font-size: 12px;
  color: #878787;
  font-weight: 400;
}

.notes-description {
  font-size: 14px;
}

.notes-list-container {
  max-height: 200px;
  overflow-y: scroll;
}

.notes-label {
  color: #878787;
  font-weight: 400;
}

#add-notes {
  height: 140px;
  border-radius: 4px;
}

.submit-notes,
.submit-notes:hover {
  background: #828293 !important;
  border-color: #828293 !important;
}

.notes-header,
.info-header {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #000000;
}

.info-container {
  padding: 15px 0;
}

.name-container {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.school-container {
  font-size: 14px;
  font-weight: 400;
}

.info-first-name {
  margin-right: 4px;
  text-transform: capitalize;
}

.info-last-name::first-letter {
  text-transform: uppercase;
}

// Scroll bar designs
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #e1dfdf;
}

::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #55555599;
}

// Dyte meeting icon wrapper button
.meeting-icon-wrapper {
  background: transparent;
  border: 0;
  padding: 1px 4px;
  color: white;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Css for last header of the class summary table
.class-summary-table thead tr > :last-child {
  text-align: center;
}

.profileImage {
  object-fit: contain;
  background-color: black;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.image-annotation {
  height: 100%;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  max-height: 100%;
  img {
    min-width: 500px;
    object-fit: contain !important;
  }
  &:hover {
    &::before {
      content: 'Click to annotate';
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.6);
      font-size: 24px;
      font-weight: 600;
      color: white;
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
    }
  }
}

.image-annotation__loading {
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  img {
    min-width: 500px;
  }
  &:hover {
    &::before {
      content: 'Loading...';
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.6);
      font-size: 24px;
      font-weight: 600;
      color: white;
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
    }
  }
}

/* DatePicker container */
.datepicker-container {
  position: relative;
  padding: 0;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

/* DatePicker input field */
.datepicker-input {
  width: 190%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

/* Focus state */
.datepicker-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.hand {
  position: absolute;
  z-index: 10;
  width: 50px;
  height: 50px;
  top: 20px;
  right: 20px;
  animation: raiseHand 2s ease-in-out infinite;
  animation-delay: 1s;
}

@keyframes raiseHand {
  0%,
  100% {
    transform: translateY(0) translateX(0);
  }
  25%,
  75% {
    transform: translateY(-5px) translateX(-5px);
  }
  50% {
    transform: translateY(-5px) translateX(5px);
  }
}

.additional-details-table {
  width: 100%;
  border-collapse: collapse;
}

.additional-details-table th,
.additional-details-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.table-cell {
  border-bottom: 1px solid black;
}

.notebook-image {
  max-width: 100px;
  cursor: pointer;
  margin-right: 10px; /* Adds margin between images */
}

.match {
  background-color: lightgreen;
}

.mismatch {
  background-color: lightcoral;
}

.custom-modal .modal-dialog {
  max-width: 90vw;
  width: 90vw;
  max-height: 90vh;
}

.modal-body-scrollable {
  max-height: 70vh;
  overflow-y: auto;
}
