.page-card {
  background-color: #2a3266;
}

.report-title {
  color: white;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.filter-card {
  background-color: #d5dbdb;
}

.student-info {
  margin-bottom: 8px;
  white-space: normal;
  word-wrap: break-word;
}

.cursor-pointer {
  cursor: pointer;
}

.no-filters-text {
  color: #2a3266;
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 32px;
  height: 100px;
  text-align: center;
}

.notebook-section {
  padding: 16px;
}

.notebook-title {
  text-align: center;
  color: white;
  padding: 12px;
  background-color: #00bbb4;
}

.notebook-subtitle {
  text-align: center;
  color: white;
  padding: 8px;
  background-color: #00bbb4;
}

.notebook-table-header {
  background-color: #00bbb4;
  color: white;
}

.notebook-small-width {
  width: 150px;
}

.notebook-large-width {
  width: 300px;
}

/* School and student information table */
.student-info-table {
  background-color: #e0f3ff;
}

/* Class information header */
.class-info-header {
  background-color: #00bbb4;
  color: white;
}

/* Date column */
.date-column {
  width: 100px;
}

/* Tutor observation section */
.tutor-observation-header {
  background-color: #00bbb4;
}

.tutor-observation-body {
  background-color: #e0f3ff;
}

/* Feedback input for regular view */
.feedback-input {
  width: 100%;
  padding: 4px 0;
  outline: none;
  border: none;
  background: none;
}

/* Feedback textarea for print view */
.feedback-textarea {
  width: 100%;
  padding: 4px 0;
  outline: none;
  border: none;
  background: none;
  resize: none;
  overflow: hidden;
  word-break: break-word;
  white-space: pre-wrap;
}

.report-header {
  background-color: #00bbb4;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-div {
  background-color: #00bbb4;
  height: 30px;
  width: 100%;
}

.uprioLogo {
  height: 50px;
  width: 150px;
  padding: 10px;
}

.footer-logo {
  display: flex;
  justify-content: space-between;
  height: 15px;
  width: 100%;
}

.custom-input-style {
  border: none;
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  font-size: inherit;
  cursor: text;
  width: 100%;
  resize: none;
  min-height: 60px;
  line-height: 1.3;
}

.print-header {
  display: none;
}

.print-footer {
  display: none;
}

@media print {
  @page {
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .print-hidden {
    display: none;
  }

  .screen-hidden {
    display: block;
  }

  .page-break {
    page-break-after: always;
    page-break-inside: avoid;
  }

  body {
    page-break-inside: avoid;
  }

  .print-header {
    display: block;
  }

  .print-footer {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    text-align: center;
  }
}

@media screen {
  .screen-hidden {
    display: none;
  }
}
