/* Reports.css */
.container-reports {
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 90%;
}

.table-report-responsive-reports {
  overflow-x: auto;
}

.table-report {
  border: 1px solid #dee2e6;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.table-report th,
.table-report td {
  border: 1px solid #dee2e6;
  padding: 12px;
  text-align: center;
  vertical-align: middle;
}

.thead-light th {
  background-color: #f8f9fa;
  color: #495057;
}

.date-picker-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  text-align: start;
  margin-bottom: 30px;
}

.table-report th,
.table-report td {
  text-align: center;
}

.table-report th:first-child,
.table-report td:first-child {
  text-align: left;
}

.table-report .h5 {
  font-weight: bold;
  color: #495057;
}

.badge-report {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .container-reports {
    width: 100%;
  }

  .table-report th,
  .table-report td {
    padding: 8px;
  }

  .badge-report {
    font-size: 0.875rem;
  }
}
